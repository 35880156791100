<template>
    <div v-if="!isExternalClientMode">
        <transition
                mode="out-in"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut">
            <div>
                <base-modal
                        title="Reset Password"
                        :footer="false"
                        :close="false"
                >
                    <div>
                        <div>
                            <fieldset style="color: #90a8af">
                                <div>
                                    <div style="width: 100%; clear: both; padding-top: 5px">
                                        <div>
                                            <base-input for-i-d="newPassword" label="New Password" element-type="input"
                                                        :input-class="{invalidMessage:errorKeys.includes('password')}"
                                                        v-model="model.password" input-type="password"/>
                                        </div>
                                        <div>
                                            <base-input for-i-d="confirmPassword" label="Confirm New Password"
                                                        element-type="input"
                                                        :input-class="{invalidMessage:errorKeys.includes('password2')}"
                                                        v-model="model.password2" input-type="password"/>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <br/>
                            <div id="card-error" style="color: red">
                                <span v-for="error in errorMessages" :key="error">{{ error }}<br/></span>
                            </div>
                            <base-button
                                    style="margin-top: 10px;"
                                    mode="blue"
                                    :link="false"
                                    @click.native="changePassword"
                            >Change Password
                            </base-button>
                            <base-button
                                    style="margin-top: 10px;"
                                    mode="orange"
                                    :link="false"
                                    @click.native="routeTo('/login')"
                            >Go To Login
                            </base-button>
                        </div>
                    </div>
                </base-modal>
            </div>
        </transition>
    </div>
</template>

<script>
import AccountService from "../../services/account/account.service";
import Swal from "sweetalert2";
import { isExternalClientMode } from '../../utility/helper-functions';

const CONDITIONS = [
    {test: (model) => model.password?.length > 0, message: 'Please fill password field.', errorKeys: ['password']},
    {
        test: (model) => model.password2?.length > 0,
        message: 'Please fill confirm password field.',
        errorKeys: ['password2']
    },
    {
        test: (model) => model.password === model.password2,
        message: 'Passwords do not match.',
        errorKeys: ['password', 'password2']
    },
]
export default {
    name: "ResetPassword",
    props: {
        token: String,
        email: String
    },
    data: function () {
        return {
            errorMessages: [],
            errorKeys: [],
            model: {
                currentPassword: '',
                password: '',
                password2: ''
            },
        };
    },
    computed: {
        hasErrors: function () {
            return this.errorMessages?.length > 0;
        },
        isExternalClientMode() {
            return isExternalClientMode();
        },
    },
    methods: {
        validate: function () {
            this.errorMessages = [];
            this.errorKeys = [];
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.model)) {
                    this.errorMessages.push(condition.message);
                    condition.errorKeys.forEach(key => this.errorKeys.push(key));
                }
            })
            return this.errorMessages.length === 0;
        },
        changePassword: function () {
            let isValid = this.validate();
            if (isValid) {
                AccountService.resetPassword({
                    password: this.model.password,
                    password_confirmation: this.model.password2,
                    nonce: this.token,
                    email: this.email
                })
                    .then(result => {
                        if (result.data.success) {
                            Swal.fire({
                                title: 'Success!',
                                type: 'info',
                                text: 'The password was successfully changed!',
                                showCancelButton: true,
                                cancelButtonColor: '#1d9deb',
                                confirmButtonColor: '#ff5656',
                                confirmButtonText: 'Login!'
                            }).then(result => {
                                if(result.value) {
                                    this.routeTo('Login');
                                }
                            });
                        } else {
                            Object.values(result.data).forEach(message => this.errorMessages.push(message[0]));
                        }
                    })
                    .catch((error) => {
                        Object.values(error?.response?.data?.data || {}).forEach(message => this.errorMessages.push(message[0]));
                    })
            }
        }
    },
    created() {

    }
}
</script>
